<template>
  <el-form
    class="income-wrap"
    ref="merchandiseFileRef"
    :rules="merchandiseFileRules"
    :model="organizationFile"
    v-loading="uploadLoading"
  >
    <div class="edit-select">
      <div style="margin-top: -40px">
        <a :href="staticFile.projectImportXls.url"
           :download="staticFile.projectImportXls.name">{{ staticFile.projectImportXls.name }}</a>
      </div>
      <div class="edit-select-item">
        <el-input
          v-model="organizationFile.fileName"
          placeholder="文件名称.xlsx 或 文件名称.xls"
          style="width: 300px"
        ></el-input>
        <el-upload
          accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          action="#"
          :multiple="false"
          :show-file-list="false"
          :on-exceed="handleExceed"
          :on-change="uploadFile"
        >
          <el-button type="default" icon="el-icon-upload2">
            上传文件
          </el-button>
        </el-upload>
      </div>
    </div>
    <div class="edit-select" style="margin-top: 10px">
      <div class="edit-select-foot">
        <el-button class="el-button-ext" @click="handleCancel">取消</el-button>
        <el-button
          class="el-button-ext"
          @click="handleSaveGoods"
          :disabled="saving"
          type="primary"
          >导入
        </el-button>
      </div>
    </div>
  </el-form>
</template>

<script>
import { uploadSalConfig, downloadSalConfig } from "@/service/sal-config";
import StaticFile from "@/assets/js/static-file";

export default {
  name: "OrganizationImport",
  data() {
    return {
      staticFile: StaticFile,
      saving: false,
      organizationFile: {
        orgId: "",
        isSave: "Y",
        identity: "self",
        fileName: "",
        file: {},
      },
      merchandiseFileRules: {
      },
      uploadLoading: false,
      buttonDisabled: false,
      uploadUrl: ''
    };
  },
  methods: {
    /* 上传错误捕获 */
    handleExceed() {
      this.toast("上传文件出错", "error");
    },
    /* 导入保存 */
    async handleSaveGoods() {
      this.$refs.merchandiseFileRef.validate((valid) => {
        if (!valid) return;
      });
      if (!this.organizationFile.isSave) {
        this.toast("模版格式不允许为空！", "warning");
        return;
      }
      if (!this.organizationFile.identity) {
        this.toast("用户身份不允许为空！", "warning");
        return;
      }
      if (!this.organizationFile.file.name) {
        this.toast("请选择要上传文件！", "warning");
        return;
      }
      this.confirmImport();
    },
    uploadFile(file) {
      this.organizationFile.fileName = file.name;
      this.organizationFile.file = file.raw;
    },
    /* 重置表单 */
    resetForm() {
      this.$refs.merchandiseFileRef.resetFields();
      this.organizationFile.fileName = "";
      this.organizationFile.file = {};
    },
    /* 通知父组件关闭dialog */
    handleCancel() {
      this.resetForm();
      this.$emit("handleCancelImportDialog");
    },
    //处理取消
    cancelImport() {
      this.saving = false;
      this.resetForm();
      this.$emit("handleCancelImportDialog");
    },
    //处理确认
    async confirmImport() {
      let formData = new FormData();
      formData.append("file", this.organizationFile.file);
      formData.append("isSave", this.organizationFile.isSave);
      formData.append("identity", this.organizationFile.identity);

      this.buttonDisabled = true;
      this.uploadLoading = true;
      this.uploadLoading = false;
      this.buttonDisabled = false;
      // const res = await uploadSalConfig(formData);
      // if (res.success) {
        // this.toast("导入成功！", "success");
        // this.uploadUrl = res.data;
      // }projectImportXls
      this.saving = false;
    },
    async downloadOrgs() {
      let prefix = ''
      let origin = ''
      let resultUrl = this.uploadUrl.replace(origin, prefix)
      const a = document.createElement('a')
      a.setAttribute('download', '')
      a.setAttribute('href', resultUrl)
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  },
};
</script>

<style scoped lang="scss">
.income-wrap {
  padding: 0 24px;
}

::v-deep .el-form-item__label {
  padding: 0;
  line-height: 24px;
}

::v-deep .el-form-item__error {
  top: 58px;
  padding-top: 2px;
}
::v-deep .dialog-footer {
  padding-top: 25px;
  text-align: center;
}

  ::v-deep .el-select, ::v-deep .el-input {
    width: 453px;
  }

.edit-select {
  .edit-select-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .edit-select-item:first-child {
    margin-bottom: 50px;
  }

  .edit-select-item:last-child {
    margin-top: 60px;
  }

  .edit-select-foot {
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: flex-end;
  }
}

.edit-message {
  margin-top: 25px;
  margin-bottom: 32px;
  text-align: left;
  font-size: 14px;
  color: #f5222d;
}
.dialog-content {
  font-size: 16px;
  color: #333333;
}
.dialog-footer {
  text-align: right;
}
</style>
